import { useEffect, useState, useRef, Fragment } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MetisMenu from 'metismenujs';

const SidebarContent = ({ t, user, toggleMenu = () => { } }) => {
    const location = useLocation();
    const [pathName, setPathName] = useState(location.pathname);
    const menu = useRef(false);
    const admin = !user.level;
    const agent = (user.level >= 3);

    const toggleMenuHandler = () => {
        if (window.innerWidth < 993) {
            toggleMenu();
        }
    };

    useEffect(() => {
        if (!menu.current) {
            menu.current = new MetisMenu('#side-menu');
        }

        if (location.pathname !== pathName) {
            setPathName(location.pathname);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location, pathName]);

    return (
        <Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{t('Navigation')}</li>

                    <li>
                        <NavLink to="/dashboard" className="waves-effect" end>
                            <i className="ri-dashboard-line"></i>
                            <span className="ms-1">{t('Dashboard')}</span>
                        </NavLink>
                    </li>

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="ri-calendar-line"></i>
                            <span className="ms-1">{t('Bookings')}</span>
                        </Link>
                        <ul className="sub-menu" onClick={toggleMenuHandler}>
                            {admin && <li><NavLink to="/bookings" end>{t('All Bookings')}</NavLink></li>}
                            <li><NavLink to="/bookings/create" end>{t('Add new Booking')}</NavLink></li>
                            {!agent && <li><NavLink to="/bookings/activity" end>{t('Activity Report')}</NavLink></li>}
                        </ul>
                    </li>

                    {!agent && <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="ri-car-line"></i>
                            <span className="ms-1">{t('Fleet Management')}</span>
                        </Link>
                        <ul className="sub-menu" onClick={toggleMenuHandler}>
                            <li><NavLink to="/vehicles" end>{t('Vehicles')}</NavLink></li>
                            {admin && <Fragment>
                                <li><NavLink to="/vehicles/models" end>{t('Vehicles Make')}</NavLink></li>
                                <li><NavLink to="/vehicles/categories" end>{t('Vehicle Categories')}</NavLink></li>
                            </Fragment>}
                            <li><NavLink to="/vehicles/calendar" end target="_blank" rel="noreferrer noopener">{t('Fleet Calendar')}</NavLink></li>
                            <li><NavLink to="/vehicles/dashboard" end>{t('Fleet Dashboard')}</NavLink></li>
                        </ul>
                    </li>}

                    <li className="menu-title">{t('Others')}</li>

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="ri-bill-line"></i>
                            <span className="ms-1">{t('Invoices')}</span>
                        </Link>
                        <ul className="sub-menu" onClick={toggleMenuHandler}>
                            {!agent && <li><NavLink to="/invoices" end>{t('All Invoices')}</NavLink></li>}
                            <li><NavLink to="/invoices/create" end>{t('Create new Invoice')}</NavLink></li>
                        </ul>
                    </li>

                    {admin && <Fragment>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-user-3-line"></i>
                                <span className="ms-1">{t('Human Resource')}</span>
                            </Link>
                            <ul className="sub-menu" onClick={toggleMenuHandler}>
                                <li><NavLink to="/users" end>{t('User Management')}</NavLink></li>
                            </ul>
                        </li>

                        <li className="menu-title">{t('Management')}</li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-settings-2-line"></i>
                                <span className="ms-1">{t('Settings')}</span>
                            </Link>
                            <ul className="sub-menu" onClick={toggleMenuHandler}>
                                <li><NavLink to="/settings" end>{t('Price Settings')}</NavLink></li>
                                <li><NavLink to="/products" end>{t('Products')}</NavLink></li>
                            </ul>
                        </li>
                    </Fragment>}

                    {admin && <Fragment>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-money-dollar-circle-line"></i>
                                <span className="ms-1">{t('Revenue')}</span>
                            </Link>
                            <ul className="sub-menu" onClick={toggleMenuHandler}>
                                <li><NavLink to="/revenue/reconciliation" end>{t('Revenue Reconciliation')}</NavLink></li>
                                <li><NavLink to="/revenue/flags" end>{t('My Flags')}</NavLink></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-bar-chart-2-line"></i>
                                <span className="ms-1">{t('Reports')}</span>
                            </Link>
                            <ul className="sub-menu" onClick={toggleMenuHandler}>
                                <li><NavLink to="/reports/analytics" end>{t('Analytics')}</NavLink></li>
                                <li><NavLink to="/reports/booking" end>{t('Booking Report')}</NavLink></li>
                                <li><NavLink to="/reports/revenue" end>{t('Revenue Report')}</NavLink></li>
                                <li><NavLink to="/reports/extensions" end>{t('Extensions Report')}</NavLink></li>
                                <li><NavLink to="/reports/extras" end>{t('Extras Report')}</NavLink></li>
                                <li><NavLink to="/reports/volume" end>{t('Work Volume Report')}</NavLink></li>
                            </ul>
                        </li>
                    </Fragment>}
                </ul>
            </div>
            <div className="leftbar-overlay d-lg-none" onClick={toggleMenuHandler}></div>
        </Fragment>
    );
};

export default connect(({ Login: { user } }) => ({ user }), {})(withTranslation()(SidebarContent));